<template>
  <div>
    <template v-if="teams.length>0">
      <VRow>
        <VCol align="right">
          <VBtn
            color="primary"
            :to="{ name : Names.R_ACCOUNT_V2_COMPANY_TEAM_CREATE }"
          >
            <VIcon left>
              fal fa-plus
            </VIcon>

            Создать команду
          </VBtn>
        </VCol>
      </VRow>

      <VRow>
        <VCol>
          <TeamTable
            :items="teams"
            :loading="loading"
            @show-team="goToEditTeam"
          />
        </VCol>
      </VRow>
    </template>
    <template v-else-if="!loading">
      <VRow>
        <VCol
          align="center"
          justify="center"
        >
          <VImg
            width="102"
            :src="require('@/assets/male-mexican-hat.png')"
          />
          <div class="empty-company">
            Нет команд в компании
          </div>
          <RouterLink :to="{ name : Names.R_ACCOUNT_V2_COMPANY_TEAM_CREATE }">
            Создать команду
          </RouterLink>
        </VCol>
      </VRow>
    </template>
  </div>
</template>

<script>
import TeamTable from '../../../components/v2/TeamTable.vue';

export default {
  name: 'TeamList',
  components: { TeamTable },
  inject: ['Names'],
  props: {
    accountId: { type: String, required: true },
    companyId: { type: String, required: true },
  },
  data() {
    return {
      teams: [],
    };
  },
  async created() {
    await this.fetch();
  },
  methods: {
    goToEditTeam(e) {
      this.$router.push({
        name: this.Names.R_ACCOUNT_V2_COMPANY_TEAM_VIEW,
        params: {
          teamId: e.id,
        },
      });
    },
    async fetch() {
      try {
        this.loading = true;
        const app = new this.$di.ddd.Orgstructure();
        const [teams] = await app.services.team.getTeams({ companyId: this.companyId });
        this.teams = teams;
      } catch (err) {
        //
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.empty-company {
  margin: 12px 0 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.3px;
  color: #0b1218;
}
</style>
