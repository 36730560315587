<template>
  <TTDataTable
    :headers="headers"
    :items="items"
    :loading="loading"
    hide-default-footer
    disable-pagination
  >
    <template #item.parent="{item, value}">
      <div class="d-flex justify-space-between">
        <div>
          {{ value ? "Да" : "Нет" }}
        </div>
        <div class="mr-2">
          <VMenu
            allow-overflow
            offset-y
            content-class="table-v-menu__wrapper"
            left
          >
            <template #activator="{ on, attrs }">
              <VIcon
                class="tt-light-blue--text ml-2"
                small
                v-bind="attrs"
                v-on="on"
              >
                far fa-ellipsis-h
              </VIcon>
            </template>
            <VList>
              <VListItem
                @click="$emit('show-team', item)"
              >
                <VIcon
                  class="tt-blue--text mr-3"
                  left
                >
                  fal fa-eye
                </VIcon>
                <VListItemTitle
                  class="tt-blue--text"
                >
                  Просмотр
                </VListItemTitle>
              </VListItem>
            </VList>
          </VMenu>
        </div>
      </div>
    </template>
  </TTDataTable>
</template>

<script>
export default {
  name: 'TeamTable',
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        { text: 'Название команды', value: 'name' },
        { text: 'Отображаемое название', value: 'displayName' },
        { text: 'Тип', value: 'teamType' },
        { text: 'Внешний идентификатор', value: 'externalId' },
        { text: 'Дочерняя', value: 'parent' },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.table-v-menu__wrapper {
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08) !important;
}
</style>
